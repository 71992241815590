// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
p,
h1,
h2,
h3,
h4,

h6,

span,
li,
input,
button {
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

label {
    font-size: small;
}

h5,
a {
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.form-check {
    margin-right: 15px;
    margin-bottom: 10px;
}

#sizesSection {
    margin-top: 10px;
}

#sizesTab {
    font-weight: bold;
    margin-top: 20px;
}

@media only screen and (max-width: 768px) {

    /* For all scrollbars */
    body,
    html {
        /* Hides scrollbar in most browsers */
        overflow: -moz-scrollbars-none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    /* WebKit-based browsers (Chrome, Safari, Edge, etc.) */
    body::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar */
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/style.css"],"names":[],"mappings":"AAMA;;;;;;;;;;;;;IAaI,iCAAiC;IACjC,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,iCAAiC;IACjC,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;;IAEI,uBAAuB;IACvB;;QAEI,qCAAqC;QACrC,8BAA8B;QAC9B,YAAY;QACZ,wBAAwB;QACxB,0BAA0B;QAC1B,qBAAqB;QACrB,YAAY;IAChB;;IAEA,uDAAuD;IACvD;QACI,aAAa;QACb,mBAAmB;IACvB;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');\n\n\n\n\n\nbody,\np,\nh1,\nh2,\nh3,\nh4,\n\nh6,\n\nspan,\nli,\ninput,\nbutton {\n    font-family: \"Roboto\", sans-serif;\n    font-optical-sizing: auto;\n    font-weight: 500;\n    font-style: normal;\n}\n\nlabel {\n    font-size: small;\n}\n\nh5,\na {\n    font-family: \"Roboto\", sans-serif;\n    font-optical-sizing: auto;\n    font-weight: 500;\n    font-style: normal;\n}\n\n.checkbox-group {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.form-check {\n    margin-right: 15px;\n    margin-bottom: 10px;\n}\n\n#sizesSection {\n    margin-top: 10px;\n}\n\n#sizesTab {\n    font-weight: bold;\n    margin-top: 20px;\n}\n\n@media only screen and (max-width: 768px) {\n\n    /* For all scrollbars */\n    body,\n    html {\n        /* Hides scrollbar in most browsers */\n        overflow: -moz-scrollbars-none;\n        /* Firefox */\n        -ms-overflow-style: none;\n        /* Internet Explorer 10+ */\n        scrollbar-width: none;\n        /* Firefox */\n    }\n\n    /* WebKit-based browsers (Chrome, Safari, Edge, etc.) */\n    body::-webkit-scrollbar {\n        display: none;\n        /* Hide scrollbar */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

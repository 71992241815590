// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    display: flex;
    flex-direction: column;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}

.card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.card h-100 {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/ShoeCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".card {\n    display: flex;\n    flex-direction: column;\n}\n\n.card-img-top {\n    height: 200px;\n    object-fit: cover;\n}\n\n.card-body {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n\n.card h-100 {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {


    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(18, 22, 33, 0.12);

}

.slider {
    width: 100%;
    height: 6px;
    background-color: #cdd9ed;
}

.slider .thumb {
    width: 23px;
    height: 23px;
    cursor: grab;
    background: #fff;
    border-radius: 50%;
    border: 3px solid #000000;
    position: relative;
    /* Add position relative */
    top: -9px;
    /* Adjust this value to vertically align the thumb */
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/RangeSlider.css"],"names":[],"mappings":"AAAA;;;IAGI,mBAAmB;IACnB,4CAA4C;;AAEhD;;AAEA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,0BAA0B;IAC1B,SAAS;IACT,oDAAoD;AACxD","sourcesContent":[".box {\n\n\n    border-radius: 10px;\n    box-shadow: 0 1px 4px rgba(18, 22, 33, 0.12);\n\n}\n\n.slider {\n    width: 100%;\n    height: 6px;\n    background-color: #cdd9ed;\n}\n\n.slider .thumb {\n    width: 23px;\n    height: 23px;\n    cursor: grab;\n    background: #fff;\n    border-radius: 50%;\n    border: 3px solid #000000;\n    position: relative;\n    /* Add position relative */\n    top: -9px;\n    /* Adjust this value to vertically align the thumb */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
